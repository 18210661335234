<template>
  <footer
    class="bg-violet-2 color-secondary flex flex-col items-center py-2 px-3"
    ref="footer"
  >
    <ul
      class="inline-flex flex-wrap justify-around gap-3 text-lg text-white underline decoration-yellow-1 mb-5"
    >
      <li>
        <a
          href="https://terresdeluttes.fr/"
          target="_blank"
          rel="noopener noreferrer"
          >Accueil Terres de Luttes</a
        >
      </li>
      <li>
        <a
          href="https://www.instagram.com/TerresdeLuttes/"
          target="_blank"
          rel="nofollow noopener"
          title="Instagram"
          ><InstagramLogo :size="48" class="material-design-icon__svg" />
        </a>
      </li>
      <li>
        <a
          href="https://www.facebook.com/TerresdeLuttes/"
          target="_blank"
          rel="nofollow noopener"
          title="Facebook"
          ><Facebook :size="30" class="material-design-icon__svg" />
        </a>
      </li>
      <li>
        <a
          href="https://twitter.com/terresdeluttes"
          target="_blank"
          rel="nofollow noopener"
          title="Twitter"
          ><Twitter :size="30" class="dark:text-white"
        /></a>
      </li>
    </ul>

    <ul
      class="inline-flex flex-wrap justify-around gap-3 text-lg text-white underline decoration-yellow-1"
    >
      <li>
        <router-link :to="{ name: RouteName.ABOUT }">{{
          t("About")
        }}</router-link>
      </li>
      <li>
        <router-link :to="{ name: RouteName.TERMS }">{{
          t("Terms")
        }}</router-link>
      </li>
      <li>
        <a
          rel="external"
          hreflang="en"
          href="https://framagit.org/framasoft/mobilizon/blob/main/LICENSE"
        >
          {{ t("License") }}
        </a>
      </li>
      <li>
        <a href="#navbar">{{ t("Back to top") }}</a>
      </li>
    </ul>
    <div class="text-center flex-1 pt-2 text-yellow-1">
      <i18n-t
        tag="span"
        keypath="Powered by {mobilizon}. © 2018 - {date} The Mobilizon Contributors - Made with the financial support of {contributors}."
      >
        <template #mobilizon>
          <a
            rel="external"
            class="text-white underline decoration-yellow-1"
            href="https://joinmobilizon.org"
            >{{ t("Mobilizon") }}</a
          >
        </template>
        <template #date
          ><span>{{ new Date().getFullYear() }}</span></template
        >
        <template #contributors>
          <a
            rel="external"
            class="text-white underline decoration-yellow-1"
            href="https://joinmobilizon.org/hall-of-fame"
            >{{ t("more than 1360 contributors") }}</a
          >
        </template>
      </i18n-t>
    </div>
  </footer>
</template>
<script setup lang="ts">
import { saveLocaleData } from "@/utils/auth";
import { loadLanguageAsync } from "@/utils/i18n";
import RouteName from "../router/name";
// import langs from "../i18n/langs.json";
import { watch } from "vue"; //computed, removed before watch, used atm and throwing error on commit
import { useI18n } from "vue-i18n";

import InstagramLogo from "./Share/InstagramLogo.vue";
import Facebook from "vue-material-design-icons/Facebook.vue";
import Twitter from "vue-material-design-icons/Twitter.vue";

const { locale, t } = useI18n({ useScope: "global" });

//Commented to avoid commit error

// const random = computed((): number => {
//   return Math.floor(Math.random() * 4) + 1;
// });

watch(locale, async () => {
  if (locale) {
    console.debug("Setting locale from footer");
    await loadLanguageAsync(locale.value as string);
    saveLocaleData(locale.value as string);
  }
});

// const isLangSelected = (lang: string): boolean => {
//   return lang === locale.value;
// };
</script>
