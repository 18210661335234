<template>
  <span class="text-primary dark:text-white">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 50 50"
      width="30px"
      height="30px"
    >
      <path
        style="
          fill: none;
          stroke: #ffff;
          stroke-width: 2;
          stroke-miterlimit: 10;
        "
        d="M16,46h18c6.627,0,12-5.373,12-12V16c0-6.627-5.373-12-12-12H16C9.373,4,4,9.373,4,16v18C4,40.627,9.373,46,16,46z"
      />
      <circle
        style="fill: none; stroke: #fff; stroke-width: 2; stroke-miterlimit: 10"
        cx="25"
        cy="25"
        r="10"
      />
      <circle cx="37" cy="13" r="2" style="fill: none; stroke: #fff" />
    </svg>
  </span>
</template>
